import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { switchMap } from 'rxjs';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss', '../auth-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordDialogComponent implements OnInit {
  loading: boolean;
  header = 'forgot_password';
  linkSent = false;
  countryPrefix: string;
  assetsDir = '/';
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    public authApiService: AuthAPIService,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(DIALOG_DATA) private data: ForgotPasswordDialogComponentData
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(this.data?.email || '', [Validators.required, Validators.email]),
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    const emailRequestBody = this.form.getRawValue();
    const phoneRequestBody = {
      phone_number: this.form.get('phone_number')?.value.substring(this.form.get('phone_country_code')?.value?.length),
      phone_country_code: this.form.get('phone_country_code')?.value,
    };

    const requestBody = this.form.get('email')?.value ? emailRequestBody : phoneRequestBody;
    if (this.form.valid) {
      this.loading = true;
      this.recaptchaV3Service
        .execute('reset_password')
        .pipe(
          switchMap((captcha_token) => {
            return this.authApiService.resetPassword({ ...requestBody, captcha_token });
          })
        )
        .subscribe(
          () => {
            this.loading = false;
            this.linkSent = true;
          },
          (error) => {
            Sentry.captureException(error.error || error, {
              tags: {
                module: 'auth-dialog',
                feature: 'forgot-password',
              },
            });
            this.loading = false;
          }
        );
    }
  }

  onCountryPrefixChange(prefix) {
    this.countryPrefix = prefix;
  }
}

export interface ForgotPasswordDialogComponentData {
  email: string;
}
