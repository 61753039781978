<div class="app-header-logo">
  <div class="app-logo">
    <img src="{{ assetsDir }}assets/logo/appic-logo-black.svg" alt="logo" />
  </div>
</div>
<div class="align-self-center lang-container">
  <mat-form-field class="lang-select" floatLabel="auto">
    <mat-select [(ngModel)]="selectedLanguage" (valueChange)="onLanguageChange($event)">
      <mat-option *ngFor="let language of getAvailableLangs()" [value]="language">
        {{ language | uppercase }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<app-lib-nested-dropdown
  class="dropdown"
  *ngIf="isLoggedIn$ | async"
  [dropData]="dropListData"
  [selectedValue]="true"
  (elementClicked)="handleLogOutClick()"
>
  <div class="profile-img">
    <img *ngIf="userSessionService.user?.profile_photo" [src]="userSessionService.user?.profile_photo" alt="Profile image" />
    <svg-icon *ngIf="!userSessionService.user" [src]="assetsDir + 'assets/icons/icon-user-blank.svg'"></svg-icon>
  </div>
</app-lib-nested-dropdown>
