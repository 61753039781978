<!--AVAILABLE-->
<div class="product" *ngIf="!shouldShowSoldOut(ticket); else soldOut">
  <div class="row p-0 m-0 align-items-center align-items-md-start">
    <div class="col p-0 m-md-0 mb-2 order-1 col-12 col-md-5">
      <div class="product-name">
        <h2>{{ ticket.title }}</h2>
      </div>
    </div>
    <div class="product-price order-1 col-7 col-sm-7 col-md-4">
      {{ ticket.price | currency: ticket.currency }}
      <span class="fee"
        >({{ 'product.inc' | transloco }} {{ ticket.service_fee | currency: ticket.currency }} {{ 'product.fee' | transloco }})</span
      >
    </div>
    <div class="defer-container p-0 col-sm-auto order-3 text-sm-right my-auto ticket-counter p-0 col-5 col-sm-5 col-md-3">
      @defer {
        <div class="btn-add-group">
          <button
            [attr.aria-label]="'product.remove_from_cart' | transloco"
            [disabled]="ticketNumber === 0"
            class="btn btn-minus"
            (click)="removeTicket()"
            [disabled]="busy"
          >
            <svg-icon src="{{ assetsDir }}assets/icons/minus-icon.svg" />
          </button>

          <span class="tickets-counter">{{ ticketNumber }}</span>

          <button
            [attr.aria-label]="'product.add_to_cart' | transloco"
            class="btn btn-plus btn-reservation"
            (click)="addTicket()"
            [disabled]="showTooltip || busy"
          >
            <svg-icon src="{{ assetsDir }}assets/icons/plus-icon.svg" />
            <div class="tooltip" [ngClass]="{ 'tooltip-show': showTooltip }">
              <div class="tooltip-container">
                <span class="tooltip-text">
                  {{ 'product.max_amount' | transloco }}
                </span>
              </div>
            </div>
          </button>
        </div>
      }
    </div>
    <div class="col-12 p-0 order-3 mt-1 align-top" *ngIf="ticket.description">
      <div *ngIf="expand" class="product-description" [innerHTML]="ticket.description" #productDescription></div>
      <button class="btn btn-more mobile mt-2" (click)="expand = !expand">
        <!-- isDescriptionCollapsed -->
        <span>{{ expand ? ('product.less' | transloco) : ('product.more' | transloco) }}</span> &nbsp;
        <svg-icon class="more-icon" [ngClass]="{ rotated: !expand }" src="{{ assetsDir }}assets/icons/chevron-icon.svg" />
      </button>
    </div>
  </div>
</div>
<!--SOLD-->
<ng-template #soldOut>
  <div class="product">
    <div class="row p-0 m-0 align-items-center">
      <div class="col p-0">
        <div class="product-name disabled">
          <h2>{{ ticket.title }}</h2>
        </div>
      </div>
      <div class="col-auto text-right my-auto">
        <div class="product-name disabled">
          {{ 'product.soldOut' | transloco }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
