import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ShopApiService } from 'src/app/home/services/shop-api.service';
import { ResetPasswordRequest } from 'src/app/home/interfaces/auth.interface';
import { CanDeactivate } from 'src/app/home/can-deactivate/can-deactivate';
import { ShopService } from 'src/app/home/services/shop.service';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { switchMap } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['../forms.component.scss'],
})
export class ResetPasswordFormComponent extends CanDeactivate implements OnInit {
  resetPasswordForm: UntypedFormGroup;
  @Output() toggleFormsEmiter = new EventEmitter<string>();
  success = false;

  constructor(
    private fB: UntypedFormBuilder,
    protected shopApiService: ShopApiService,
    protected shopService: ShopService,
    private authApiService: AuthAPIService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super(shopApiService, shopService);
  }

  ngOnInit() {
    this.resetPasswordForm = this.fB.group({
      email: this.fB.control('', [Validators.required, Validators.email]),
    });
  }

  getFormControl(name) {
    return this.resetPasswordForm.get(name);
  }

  onSubmit() {
    const resetPasswordRequest: ResetPasswordRequest = this.resetPasswordForm.value;
    this.recaptchaV3Service
      .execute('reset_password')
      .pipe(
        switchMap((captcha_token) => {
          return this.authApiService.resetPassword({ ...resetPasswordRequest, captcha_token });
        })
      )
      .subscribe(
        (response: any) => {
          if (response.status === 1) {
            this.success = true;
          } else if (response.status === 0) {
            this.getFormControl('email').setErrors({
              incorrect_email: true,
            });
          }
        },
        (error) => {
          Sentry.captureException(error.error || error, {
            tags: {
              feature: 'password-reset',
            },
          });
        }
      );
  }

  toggleForms(form) {
    this.toggleFormsEmiter.emit(form);
  }

  getForm(): UntypedFormGroup {
    return this.resetPasswordForm;
  }

  canCloseTab(): boolean {
    return !this.getForm().dirty;
  }
}
