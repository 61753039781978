import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found.component';
import { MaterialModule } from './material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ExtraComponentModule } from './home/components/extra.component.module';
import { CanDeactivateGuard } from './home/can-deactivate/can-deactivate.guard';
import { ShopService } from './home/services/shop.service';
import { CustomHttpInterceptorService } from './home/services/custom-http-interceptor.service';
import { TranslocoModule } from '@ngneat/transloco';

const routes: Routes = [
  {
    path: 'support',
    loadChildren: () => import('./home/views/support/support.module').then((m) => m.SupportModule),
  },
  {
    path: 'what-is-sealed-ticket',
    loadChildren: () => import('./what-is-sealed-ticket/what-is-sealed-ticket.module').then((m) => m.WhatIsSealedTicketModule),
  },
  { path: 'notfound', component: NotFoundComponent },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./events/events.module').then((m) => m.EventsModule),
  },
  {
    path: ':name',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: ':name/**',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canDeactivate: [CanDeactivateGuard],
  },

  { path: '**', component: NotFoundComponent },
];

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [CommonModule, RouterModule.forRoot(routes), MaterialModule, HttpClientModule, ExtraComponentModule, TranslocoModule],
  exports: [RouterModule],
  providers: [ShopService, { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true }],
})
export class AppRoutingModule {}
