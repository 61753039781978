import { Component, OnInit, Input, EventEmitter, Output, Inject, PLATFORM_ID } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';
import { ShopApiService } from 'src/app/home/services/shop-api.service';
import { ShopService } from 'src/app/home/services/shop.service';

import { CanDeactivate } from 'src/app/home/can-deactivate/can-deactivate';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { ValidateEmailRequest, ValidateEmailResponse } from 'src/app/home/interfaces/auth.interface';
import { debounceTime, distinctUntilChanged, startWith, take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthService } from 'src/app/home/services/auth.service';
import { OrderSummaryService } from 'src/app/home/services/order-summary.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-details-form',
  templateUrl: './details-form.component.html',
  styleUrls: ['../forms.component.scss', './details-form.component.scss'],
})
export class DetailsFormComponent extends CanDeactivate implements OnInit {
  detailsForm: UntypedFormGroup;
  userExistsWarning = false;
  @Input('color') eventColor;

  @Output() formInitEmitter = new EventEmitter<UntypedFormGroup>();

  now: Date = new Date();
  startDate: Date = new Date(2000, 1, 1);
  filteredCities: Subscription;
  cities;
  selectedCity;
  emailInput;
  emailValidated = false;
  constructor(
    private fB: UntypedFormBuilder,
    protected shopApiService: ShopApiService,
    protected shopService: ShopService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    private authApiService: AuthAPIService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authService: AuthService,
    private orderSummaryService: OrderSummaryService
  ) {
    super(shopApiService, shopService);
  }

  ngOnInit() {
    this.detailsForm = this.fB.group({
      email: this.fB.control('', [Validators.required, Validators.email]),
      first_name: this.fB.control('', [Validators.required]),
      last_name: this.fB.control('', [Validators.required]),
    });

    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        const formData = JSON.parse(sessionStorage.getItem('formData'));
        if (formData) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { birth_date, ...rest } = formData;
          this.detailsForm.patchValue({ ...rest /* , birth_date: new Date(birth_date) */ });
        }
      }
    }, 0);
    this.detailsForm
      .get('email')
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((model) => {
        if (model && model !== '') {
          this.emailValidated = false;
          this.emitFormSubmittable();
          this.emailInput = model;
          if (!this.getFormControl('email').invalid) {
            this.validateEmail();
          }
        }
      });
    combineLatest(this.watchFormExceptControl('email')).subscribe(() => {
      this.detailsForm.updateValueAndValidity();

      this.emitFormSubmittable();
    });
    this.formInitEmitter.emit(this.detailsForm);
  }

  getFormControl(name) {
    return this.detailsForm.get(name) as FormControl;
  }

  getForm(): UntypedFormGroup {
    return this.detailsForm;
  }

  canCloseTab(): boolean {
    return !this.getForm().dirty;
  }

  emitFormSubmittable() {
    const submittable = this.detailsForm.valid && this.emailValidated;
    this.orderSummaryService.options = { ...this.orderSummaryService.options, nextButtonDisabled: !submittable };
  }

  watchFormExceptControl(exceptionKeyControl: string): Array<Observable<any>> {
    return Object.entries(this.detailsForm.controls)
      .filter(([key]) => key !== exceptionKeyControl)
      .map(([key]) => {
        return this.detailsForm.controls[key].valueChanges.pipe(startWith(''));
      });
  }

  validateEmail() {
    this.userExistsWarning = false;
    if (this.getFormControl('email')?.value) {
      if (this.getFormControl('email')?.valid) {
        this.recaptchaV3Service
          .execute('validateEmail')
          .pipe(take(1))
          .subscribe((token) => {
            const body: ValidateEmailRequest = {
              email: this.getFormControl('email').value,
              captcha_token: token,
            };

            this.authApiService.validateEmail(body).subscribe(
              (res: ValidateEmailResponse) => {
                this.emailValidated = true;

                if (res?.code === 'user_exists') {
                  this.userExistsWarning = true;
                }
                if (res?.code === 'not_deliverable') {
                  this.getFormControl('email')?.setErrors({ not_deliverable: true });
                }
                this.emitFormSubmittable();
                this.getFormControl('email').markAsTouched();
              },
              (error) => {
                this.emailValidated = false;
                if (error.error?.field_errors) return;
                Sentry.captureException(error.error || error, {
                  tags: {
                    feature: 'details-form',
                  },
                });
              }
            );
          });
      }
    }
  }
}
