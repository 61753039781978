import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';

import { AuthAPIService } from '../../services/auth-api.service';
import { ShopService } from '../../services/shop.service';
import { UserSessionService } from '../../services/user-session.service';
import { Language } from '../navigation/navigation.component';
import { map } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-global-navigation',
  templateUrl: './global-navigation.component.html',
  styleUrls: ['./global-navigation.component.scss'],
})
export class GlobalNavigationComponent implements OnInit {
  assetsDir = '/';
  selectedLanguage;
  languages: Language[] = [
    {
      value: 0,
      display: 'EN',
      code: 'en',
    },
    {
      value: 1,
      display: 'NL',
      code: 'nl',
    },
  ];
  dropListData = [];
  constructor(
    private translocoService: TranslocoService,
    private authApiService: AuthAPIService,
    public userSessionService: UserSessionService,
    private shopService: ShopService,
    private router: Router,
    private authService: AuthService
  ) {}
  isLoggedIn$ = this.userSessionService.user$.pipe(
    map((user) => {
      return user && user.buyer_type !== 'not-member';
    })
  );
  ngOnInit() {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    this.translocoService.selectTranslate('global.log_out').subscribe((tran) => {
      this.dropListData = [
        {
          title: tran,
          value: 0,
        },
      ];
    });
    const lang = this.languages.find((obj) => obj.code === this.translocoService.getActiveLang());
    this.selectedLanguage = lang.code;
    this.translocoService.langChanges$.subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  handleLogOutClick() {
    const logoutCb = () => {
      if (!this.shopService?.shop?.url_extension || this.router.url.includes('summary?order')) {
        this.userSessionService.disableGetProfileInit = true;
        this.router.navigate([]);
      } else {
        this.router.navigate([this.shopService.shop.url_extension, 'tickets']);
      }
    };
    this.authService.logOut(logoutCb.bind(this));
  }

  getAvailableLangs() {
    return this.translocoService.getAvailableLangs().map((lang) => (typeof lang === 'string' ? lang : lang.id));
  }

  onLanguageChange(lang: string) {
    this.authApiService.setSessionLanguage(lang).subscribe(
      () => {},
      () => {
        const levelError: Sentry.SeverityLevel = 'error';
        Sentry.captureMessage('Error occurred during saving language in session', levelError);
      }
    );
    this.translocoService.setActiveLang(lang);
    this.translocoService.setDefaultLang(lang);
  }
}
