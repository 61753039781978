import { Injectable } from '@angular/core';
import { AuthDialogComponent, AuthDialogComponentClose, AuthDialogComponentData } from '../components/auth-dialog/auth-dialog.component';
import { DialogService } from './dialog.service';
import { AuthDialogType } from '../interfaces/auth.interface';
import { User } from '../interfaces/user.interface';
import { AuthAPIService } from './auth-api.service';
import { BehaviorSubject, catchError, iif, of, switchMap } from 'rxjs';
import { isUserVerified } from 'src/app/utils/isUserVerified';
import * as Sentry from '@sentry/angular';
import { ShopApiService } from './shop-api.service';
import { UserSessionService } from './user-session.service';
import { TranslocoService } from '@ngneat/transloco';
import { ShopService } from './shop.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authChecked: boolean;
  meChange: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  constructor(
    private dialogService: DialogService,
    private translocoService: TranslocoService,
    private authApiService: AuthAPIService,
    public userSessionService: UserSessionService,
    private shopApiService: ShopApiService,
    private shopService: ShopService
  ) {}

  openAuthDialog(dialogConfig?: DialogConfig) {
    const config = {
      width: dialogConfig?.width || '680px',
      data: dialogConfig?.data,
    };
    return this.dialogService.openDialog<AuthDialogComponentData, AuthDialogComponentClose>(AuthDialogComponent, {
      panelClass: ['dialog-auth', 'dialog-primary'],
      ...config,
    });
  }
  checkAuthAfterRegister() {
    this.authApiService.profile().subscribe({
      next: (me) => {
        this.setMe(me);
        if (!isUserVerified(me)) {
          this.dialogService.openDialog<AuthDialogComponentData, AuthDialogComponentClose>(AuthDialogComponent, {
            width: '600px',
            data: {
              type: AuthDialogType.ConfirmationCode,
            },
          });
        }
      },
      error: () => {
        this.authChecked = true;
      },
    });
  }

  setMe(me: User) {
    this.meChange.next(me);
  }

  logOut(succesCb = () => {}) {
    this.userSessionService.user = null;
    sessionStorage.clear();

    let obs = of({});
    if (this.shopService.shoppingCart) {
      obs = this.shopApiService.deleteShoppingCart(this.shopService.shoppingCart.uuid);
    }
    obs
      .pipe(
        catchError(() => {
          return of({});
        }),
        switchMap(() => {
          return this.authApiService.deleteProfile(this.shopService.shop.uuid);
        })
      )
      .subscribe(
        (response: any) => {
          this.userSessionService.user = null;
          this.shopService.source = response.source;
          this.shopService.campaign = response.campaign;
          this.authApiService.setSessionLanguage(this.translocoService.getDefaultLang()).subscribe(
            () => {
              succesCb();
            },
            (error) => {
              succesCb();
              Sentry.captureException(error.error || error, {
                tags: {
                  module: 'auth',
                  feature: 'languageChange',
                },
                extra: { message: 'Error occurred during saving language in session' },
              });
            }
          );
        },
        (error) => {
          Sentry.captureException(error.error || error, {
            tags: {
              module: 'auth',
              feature: 'logout',
            },
            extra: { message: 'Error occurred during logout' },
          });
        }
      );
  }
}
export interface DialogConfig {
  width?: string;
  data?: any;
}
