<form class="standard-form mt-4" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12 text-center">
      <h5>{{ 'details.reset_password.password_reset' | transloco }}</h5>
    </div>
  </div>
  <div class="row justify-content-center mt-4">
    <div class="col-10 text-center">
      <p>{{ 'details.reset_password.reset_info' | transloco }}</p>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 mb-2">
      <mat-form-field>
        <input matInput placeholder="{{ 'details.reset_password.e-mail' | transloco }}" formControlName="email" required />
        <mat-error *ngIf="getFormControl('email').invalid">
          <ng-container *ngIf="getFormControl('email').errors.required">
            {{ 'details.reset_password.field_is_required' | transloco }}
          </ng-container>
          <ng-container *ngIf="getFormControl('email').errors.email">
            {{ 'details.reset_password.not_a_valid_email' | transloco }}
          </ng-container>
          <ng-container *ngIf="getFormControl('email').errors.not_a_valid_email">
            {{ 'details.reset_password.incorrect_email' | transloco }}
          </ng-container>
        </mat-error>
        <mat-hint *ngIf="success">
          <span class="success-message">{{ 'details.reset_password.success_reset' | transloco }}</span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-4 mb-4 justify-content-center">
    <div class="col-sm-6 mb-3 order-0 order-sm-1">
      <button type="submit" class="btn btn-primary" [disabled]="!resetPasswordForm.valid">
        {{ 'details.reset_password.reset_my_password' | transloco }}
      </button>
    </div>
    <div class="col-sm-3 order-1 order-sm-0">
      <button type="button" class="btn btn-secondary" (click)="toggleForms('resetPassword')">
        {{ 'global.back' | transloco }}
      </button>
    </div>
  </div>
</form>
