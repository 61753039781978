import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_ID, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthAPIService } from './home/services/auth-api.service';

import { environment } from '../environments/environment';

import * as Sentry from '@sentry/angular';
import { RouterExtService } from './routerExt.service';
import { CanDeactivateGuard } from './home/can-deactivate/can-deactivate.guard';
import { UserSessionService } from './home/services/user-session.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslocoRootModule } from './transloco-root.module';
import { CustomHttpInterceptorService } from './home/services/custom-http-interceptor.service';
import { CustomSentryErrorHandler } from './customSentryErrorHandler';
import { GlobalErrorHandler } from './GlobalErrorHandler';

if (environment.sentry_dsn) {
  Sentry.init({
    dsn: environment.sentry_dsn,
  });
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    RecaptchaV3Module,
    AngularSvgIconModule.forRoot(),
    TranslocoRootModule,
  ],
  exports: [AppRoutingModule],
  providers: [
    UserSessionService,
    provideClientHydration(withHttpTransferCacheOptions({ includeHeaders: ['date'] })),
    AuthAPIService,
    RouterExtService,
    {
      provide: CustomSentryErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    CanDeactivateGuard,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha },
    { provide: APP_ID, useValue: 'my-app' },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private routerExtService: RouterExtService) {}
}
